@font-face {
    font-family: 'NSCJKrL';//'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 100;
    src: url("./fonts/NotoSansKR-Light.otf") format('truetype'),
        url("./fonts/NotoSansKR-Light.woff2") format('woff2'),
        url("./fonts/NotoSansKR-Light.woff") format('woff'),
}

@font-face {
    font-family: 'NSCJKrR';//'Noto Sans CJK KR';
    font-style: normal;
    font-weight: normal;
    src: url("./fonts/NotoSansKR-Regular.otf") format('truetype'),
        url("./fonts/NotoSansKR-Regular.woff2") format('woff2'),
        url("./fonts/NotoSansKR-Regular.woff") format('woff'),
}

@font-face {
    font-family: 'NSCJKrM';//'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 500;
    src: url("./fonts/NotoSansKR-Medium.otf") format('truetype'),
        url("./fonts/NotoSansKR-Medium.woff2") format('woff2'),
        url("./fonts/NotoSansKR-Medium.woff") format('woff'),
}

@font-face {
    font-family: 'NSCJKrB';//'Noto Sans CJK KR';
    font-style: normal;
    font-weight: bold;
    src: url("./fonts/NotoSansKR-Bold.otf") format('truetype'),
        url("./fonts/NotoSansKR-Bold.woff2") format('woff2'),
        url("./fonts/NotoSansKR-Bold.woff") format('woff'),
}

@font-face {
  font-family: 'NSCJKrK';//'Noto Sans CJK KR';
  font-style: normal;
  font-weight: 900;
  src: url("./fonts/NotoSansKR-Black.otf") format('truetype'),
      url("./fonts/NotoSansKR-Black.woff2") format('woff2'),
      url("./fonts/NotoSansKR-Black.woff") format('woff'),
}

@font-face {
    font-family: 'SHSNeoR';
    font-style: normal;
    font-weight: normal;
    src: url("./fonts/SpoqaHanSansNeo-Regular.woff2") format('woff2'),
        url("./fonts/SpoqaHanSansNeo-Regular.woff") format('woff'),
        url("./fonts/SpoqaHanSansNeo-Regular.otf") format('truetype')
}

@font-face {
    font-family: 'GothamB';
    font-style: normal;
    font-weight: bold;
    src: url('./fonts/Gotham-Bold.ttf') format('truetype'),
        url('./fonts/Gotham-Bold.otf') format('truetype')
  }

  @font-face {
    font-family: 'TimesNRBI';
    font-style: normal;
    font-weight: bold;
    src: url('./fonts/Times new roman bold italic.ttf') format('truetype'),
  }

  @font-face {
    font-family: 'GmarketSansTTFBold';
    font-style: normal;
    font-weight: bold;
    src: url('./fonts/GmarketSansTTFBold.ttf') format('truetype'),
  }

  @font-face {
    font-family: 'GmarketSansTTFLight';//'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 100;
    src: url('./fonts/GmarketSansTTFLight.ttf') format('truetype'),
  }

  @font-face {
    font-family: 'NanumSqEB';
    font-style: normal;
    font-weight: bold;
    src: url("./fonts/NanumSquareEB.woff") format('woff'),
        url('./fonts/NanumSquareEB.ttf') format('truetype'),
        url('./fonts/NanumSquareEB.eot') format('truetype')
  }